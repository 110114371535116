import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Page from "../components/page";
import BlogPost from "../components/blog-post";

export default function BlogPostPage(props) {
  return (
    <Layout {...props.data.page}>
      <Page>
        <BlogPost showContent {...props.data.page}></BlogPost>
      </Page>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String!) {
    page: contentfulBlogPost(id: { eq: $id }) {
      title
      slug
      text {
        childMarkdownRemark {
          html
        }
      }
      createDate
      author
      tags
      mainPhoto {
        gatsbyImageData(layout: CONSTRAINED)
        title
      }
    }
  }
`;
