import * as React from "react";
import * as styles from "./blog-post.module.css";
import Heading from "./heading";
import Section from "./section";
import { Link } from "gatsby";
import Moment from "react-moment";
import 'moment/locale/pl'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MarkdownText from "../components/markdown-text";

export default function BlogPost(blogPost) {
    var body = <Section className={styles.blogPost}>
        {blogPost.mainPhoto && (
            <GatsbyImage
                image={getImage(blogPost.mainPhoto)}
                alt={blogPost.mainPhoto.title}
                className={styles.image}
            />
        )}
        <div className={styles.heading}><Heading>{blogPost.title}</Heading></div>
        <div className={styles.authorAndDate}><Moment locale="pl" fromNow>{blogPost.createDate}</Moment> @ {blogPost.author}</div>
        {blogPost.showContent && <MarkdownText className={styles.blogText} {...blogPost.text} />}
    </Section>

    if (blogPost.asLink) {
        return <Link className={styles.link} to={`/${blogPost.slug}`}>{body}</Link>
    }

    return body;
}